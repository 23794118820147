<template>
  <div class="h-screen flex flex-col">
    <div v-if="updateExists" class="flex items-center justify-around absolute z-50 h-screen w-full backdrop-blur-sm ">
      <div class="flex flex-col justify-between bg-white rounded-xl p-4 custom-shadow h-1/4 w-fit">
        <h3 class="text-nav-btn text-xl">New version available!</h3>
        <p class="text-gray-500">A newer version is available. Click on the button to load it!</p>
        <button
            class="inline-flex justify-center rounded-md border border-transparent bg-nav-btn px-4 py-2 text-sm font-medium text-white"
            @click="refreshApp">
          Update
        </button>
      </div>
    </div>
    <NavComponent class=""></NavComponent>
    <div class="flex justify-center w-full flex-grow bg-background text-nav overflow-y-auto">
      <router-view class="h-full w-full place-self-center"></router-view>
    </div>
    <Footer></Footer>
    <cookie-banner class="z-50" v-if="!cookiePreferencesSet" @cookie-preferences-set="cookiePreferencesSet = true" />
  </div>
</template>

<style>
</style>
<script>
import SideBar from "@/components/SideBar";
import NavComponent from "@/components/NavComponent";
import update from './mixins/update'
import CookieBanner from "@/components/CookieBanner";
import Footer from "@/components/Footer";
export default {
  mixins: [update],
  components: {Footer, CookieBanner, SideBar, NavComponent},
  data() {
    return {
      cookiePreferencesSet: false
    }
  },
  created() {
    const cookiePreferences = JSON.parse(localStorage.getItem('cookiePreferences'));
    if (cookiePreferences) {
      this.cookiePreferencesSet = cookiePreferences.functional && cookiePreferences.analytics;
    }
  }
}
</script>
